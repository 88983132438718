import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";

function nextI(i: number, array: number[]) {
	const jump = array[i];
	const nextI = (jump + i) % array.length;
	return nextI >= 0 ? nextI : nextI + array.length;
}

export function hasSingleCycle(array: number[]) {
	let jumps = 0;
	let i = 0;
	while (jumps < array.length) {
		if (i === 0 && jumps > 0) return false;
		i = nextI(i, array);
		jumps++;
	}

	return i === 0;
}

export function HasSingleCycle() {
	const [data, setData] = useState([1, 1, 1, 1]);
	const [answer, setAnswer] = useState(hasSingleCycle(data));
	const handleChangeForData = (event: React.ChangeEvent<HTMLInputElement>) => {
		let array = event.target.value;
		let newData = array.split(',').map(Number);
		setData(newData);
		let newAnswer = hasSingleCycle(newData);
		setAnswer(newAnswer);
	};
	return (
		<>
			<div>
				<Typography>Single Cycle In Jumping Array</Typography>
			</div>
			<div>
				<TextField
					id="standard-basic"
					label="Array"
					defaultValue={data}
					onChange={handleChangeForData}
				/>
			</div>
			<div><Typography>{answer ? 'Success' : 'Failure'}</Typography></div>
		</>
	);
}