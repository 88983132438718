import React, { useState } from 'react';
import { TextField, Typography } from "@material-ui/core";
import './App.css';

function levenshteinDistance(str1: string, str2: string) {
    let edits: number[][] = Array.from(Array(str1.length + 1), () => new Array(str2.length + 1));
    for (let i = 0; i < str1.length + 1; i++) {
        edits[i][0] = i;
    }
    for (let j = 0; j < str2.length + 1; j++) {
        edits[0][j] = j;
    }
    for (let i = 1; i < str1.length + 1; i++) {
        for (let j = 1; j < str2.length + 1; j++) {
            const subsCost = str1[i - 1] === str2[j - 1] ? 0 : 1;
            edits[i][j] = Math.min(
                1 + edits[i - 1][j],
                1 + edits[i][j - 1],
                subsCost + edits[i - 1][j - 1]
            );
        }
    }
    return edits[str1.length][str2.length];
}

export function LevenshteinDistanceForm() {

    const [data, setData] = useState({ a: "abc", b: "abcd" });
    const [distance, setDistance] = useState(levenshteinDistance(data.a, data.b));

    const handleChangeForA = (event: React.ChangeEvent<HTMLInputElement>) => {
        let a = event.target.value;
        let newData = { a: a, b: data.b };
        setData(newData);
        let newDistance = levenshteinDistance(newData.a, newData.b);
        setDistance(newDistance);
    }

    const handleChangeForB = (event: React.ChangeEvent<HTMLInputElement>) => {
        let b = event.target.value;
        let newData = { a: data.a, b: b };
        setData(newData);
        let newDistance = levenshteinDistance(newData.a, newData.b);
        setDistance(newDistance);
    }

    return (
        <>
            <div>
                <TextField
                    id="standard-basic"
                    label="A"
                    defaultValue={data.a}
                    onChange={handleChangeForA}
                />
            </div>

            <div>
                <TextField
                    id="standard-basic"
                    label="B"
                    defaultValue={data.b}
                    onChange={handleChangeForB}
                />
            </div>

            <div> <Typography>Distance = {distance}</Typography></div>
        </>
    );
}
