import React from 'react';
import './App.css';
import { HasSingleCycle } from './singleCycleWithJumps';
import { LevenshteinDistanceForm } from './levenshtein';
import { Link, Route, Switch } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Switch>
          <Route exact path="/">
            <div><Link to="/levenshtein">Levenshtein Distance</Link></div>
            <div><Link to="/arrayJumpsWithSingleCycle">Array Jumps With Single Cycle</Link></div>
          </Route>
          <Route exact path="/levenshtein">
            <p>
              <LevenshteinDistanceForm />
            </p>
          </Route>
          <Route exact path="/arrayJumpsWithSingleCycle">
            <HasSingleCycle />
          </Route>
        </Switch>
      </header>
    </div>
  );
}

export default App;
